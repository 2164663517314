var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      },
      [_c("VLoadSpinner", { attrs: { "on-page": "cart-payment" } })],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
      },
      [
        _c("cart-heading", {
          attrs: {
            title: "Your order total is $" + _vm.cartTotalAmount,
            subtitle:
              "After successful payment you will be redirected to the download page.",
          },
        }),
        _c(
          "form",
          { staticClass: "form formLayout", attrs: { name: "payment" } },
          [
            _c("div", { staticClass: "formGroup" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.email,
                    expression: "customer.email",
                  },
                ],
                staticClass: "formInput formInput--fullWidth",
                attrs: { placeholder: "Delivery Email" },
                domProps: { value: _vm.customer.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.customer, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _c("input", {
              staticStyle: {
                position: "absolute",
                left: "-9999px",
                width: "1px",
                height: "1px",
              },
              attrs: { type: "submit", value: "submit" },
            }),
          ]
        ),
        _vm.paymentErrorShort
          ? _c("div", { staticClass: "form__error" }, [
              _vm._v(_vm._s(_vm.paymentErrorShort)),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "cartNavigation cartNavigation--btnFullWidthWrapper" },
          [
            _c(
              "a",
              {
                staticClass: "btn btn--lg btn--darkGrey btn--border",
                on: {
                  click: function ($event) {
                    return _vm.SET_CART_STEP(_vm.CartStep.Review)
                  },
                },
              },
              [_vm._v("PREVIOUS")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn--lg btn--withIconLeft btn--paypal",
                on: { click: _vm.payWithPaypal },
              },
              [
                _c("icon", { attrs: { name: "paypal" } }),
                _c("span", [_vm._v("Checkout with PayPal")]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }